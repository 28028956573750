@import "bootstrap";

$bootstrap-icons-font-dir: "~bootstrap-icons/font/fonts";
@import "~bootstrap-icons/font/bootstrap-icons";

body {
    height: auto;
}

/* content */
.content {
    .row {
        min-height: 100%;
    }
}

.flag {
    object-fit: cover;
    border-radius: 20px;
    width: 100%;
    height: 100%;

    &.fit-left {
        object-position: left;
    }

    &.fit-right {
        object-position: right;
    }
}

.flagHover {
    transform: translate(0, 0);
    transition: transform .4s ease-out;

    &:hover {
        transform: translate(5px, 5px);
    }
}

.statusMark {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    transform: translate(-50%, -50%);

    &.online {
        background-color: limegreen;
    }

    &.offline {
        background-color: $danger;
    }
}

.providerIcon {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 2;
    transform: translate(50%, 50%);
}

.offlineDimDark {
    opacity: .5;
    border-radius: 20px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    background-color: black;
}

.spinner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 1.1rem;
    z-index: 2;
    justify-content: center;

    .spinner-border {
        width: 100%;
        height: 100%;
    }
}

.marquee {
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    display: inline-block;
    width: 100%;
    height: 1.2em;
    mask: linear-gradient(to right, transparent 0%, black 10%, black 90%, transparent 100%);

    p {
        animation: marquee1 60s linear infinite;
        position: absolute;
        display: inline-block;
        width: auto;
        height: 100%;
        left: 100%;

        @keyframes marquee1 {
            0% {
                transform: translate(0, 0);
            }
            100% {
                transform: translate(-150%, 0);
            }
        }
    }

}

.wsDisconnected {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: $zindex-toast;
}

.banner {
    width: 100%;
    background-color: var(--bs-body-bg);
    cursor: pointer;
}

/* Rainbow button */
.rainbow-btn {
    transition: all .2s ease-in-out;
    background-image: linear-gradient(90deg, #00C0FF, #FFCF00, #FC4F4F, #00C0FF);
    position: relative;
    height: 2.5rem;
    background-size: 400% 400%;
    animation: rainbow 5s infinite linear;

    &:hover {
        box-shadow: white 0 0 10px 0;

        & > div {
            background-color: $blue-700;
        }
    }

    @keyframes rainbow {
        0% {
            background-position: 0 50%;
        }
        100% {
            background-position: 400% 50%;
        }
    }

    & > div {
        background-color: $primary;
        position: absolute;
        top: 4px;
        left: 4px;
        right: 4px;
        bottom: 4px;
    }
}

/* powerBtn */
@mixin powerBtn($color) {
    border-radius: 50%;
    box-shadow: 0 0 0 0 var(--bs-btn-bg);
    animation: pulse 1.5s infinite;
    transition: transform .2s ease-in-out;

    @keyframes pulse {
        0% {
            box-shadow: 0 0 0 0 var(--bs-btn-bg);
        }
        100% {
            box-shadow: 0 0 0 20px rgba(var(--bs-primary-rgb), 0);
        }
    }

    img {
        width: 80%;
        height: 80%;
    }

    &:active, &:disabled {
        animation: none;
    }

    &:active {
        transform: scale(0.9);
    }

    svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: rotate(-90deg);

        #anime {
            stroke-dashoffset: 313;
            stroke-dasharray: 313;
            opacity: 0;
            stroke: white;

            &:active:not(.loading) {
                opacity: 1;
                stroke-dashoffset: 0;
                stroke: $color;
                transition: stroke-dashoffset 2s linear, stroke 2s linear;
            }

            &.loading {
                @keyframes loadCircle {
                    0% {
                        stroke-dashoffset: 0;
                    }
                    100% {
                        stroke-dashoffset: -313-313;
                    }
                }
                animation: loadCircle 1.5s infinite linear;
                opacity: 1;
            }
        }
    }
}

.powerBtn {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    -webkit-touch-callout: none !important;

    &.btn-danger {
        @include powerBtn(red);
    }

    &.btn-success {
        @include powerBtn(limegreen);
    }
}

.profileImg {
    width: 100%;
    height: auto;
}

/* download-anime */
.download-anime {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99999;
    transform: translate(0, 0);
    opacity: 0.8;
    animation: a .2s ease-in-out forwards;

    img {
        width: 100%;
        height: auto;
        transform: translate(0, 0) scale(1);
        animation: b .2s ease-in-out forwards;

        @keyframes b {
            from {
                transform: translate(0, 0) scale(1);
            }
            to {
                transform: translate(0, var(--top)) scale(0.2);
            }
        }
    }

    @keyframes a {
        from {
            transform: translate(0, 0);
        }
        to {
            transform: translate(var(--right), 0);
        }
    }
}

/* chooseProfile_btn */
.chooseProfile_btn {
    img {
        transition: opacity .2s ease-in-out, transform .2s ease-in-out;

        &:hover {
            opacity: 0.7;
        }

        &:active {
            transform: scale(0.9);
        }
    }
}

/* overwrite modal BG */
.modal-content {
    background-color: var(--bs-body-bg);
}

/* loading-screen */
.loading-screen {
    position: fixed;
    z-index: $zindex-toast;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: rgba(47, 47, 47, 0.5);
    transition: opacity .5s ease-in-out;
}

/* error-screen */
.error-screen {
    @extend .loading-screen;
    background-color: rgba(47, 47, 47);
}

/* anime-background */
.anime-background {
    position: fixed;
    z-index: -20;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    &:before {
        content: '';
        background-color: black;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: .6;
        z-index: 1;
    }

    .anime-background-img {
        position: absolute;
        top: 0;
        left: 0;
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 100%;
        transition: opacity 1s ease-in-out;

        &.img1 {
            background-image: url("../assets/images/webp/paperwall_2.webp");
        }

        &.img2 {
            background-image: url("../assets/images/webp/paperwall_3.webp");
        }

        &.img3 {
            background-image: url("../assets/images/webp/paperwall_4.webp");
        }
    }
}

/* bubbles start */
.bubbles {
    position: fixed;
    z-index: -10;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    .bubble {
        position: absolute;
        left: var(--bubble-left-offset);
        bottom: calc(0px - var(--bubble-size));
        display: block;
        width: var(--bubble-size);
        height: var(--bubble-size);
        border-radius: 50%;
        animation: float-up var(--bubble-float-duration) var(--bubble-float-delay) ease-in infinite;
        transition: opacity .5s ease-out;

        &::before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: hsla(183, 100%, 85%, 0.3);
            border-radius: inherit;
            animation: var(--bubble-sway-type) var(--bubble-sway-duration) var(--bubble-sway-delay) ease-in-out alternate infinite;
            box-shadow: 0 0 5px 0 rgba(183, 100%, 85%, 0.3);

        }
    }
}

@keyframes float-up {
    to {
        transform: translateY(-175vh);
    }
}

@keyframes sway-left-to-right {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(100%);
    }
}

@keyframes sway-right-to-left {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }
}

/* bubbles end */

/* danger-border */
.danger-border {
    animation: danger-pulse 1s infinite;
    border-radius: 5px;

    @keyframes danger-pulse {
        0% {
            box-shadow: 0 0 0 0 $danger;
        }
        100% {
            box-shadow: 0 0 0 10px rgba($danger, 0);
        }
    }
}

/* iframe-background */
.iframe-background {
    position: fixed;
    z-index: -20;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}